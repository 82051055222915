import momentTz from "moment-timezone";

import {SHORT_WEEK_DAYS} from "~constants";

export function formatDate(date: string, format = "MM/DD/YYYY") {
  return momentTz.tz(date, momentTz.tz.guess()).format(format);
}

export const getShortWeekDay = (day: number) => SHORT_WEEK_DAYS[day].concat(".");

export const addMsToDate = (date: string, ms: number, format = "MM/DD/YYYY") => {
  return momentTz.tz(date, momentTz.tz.guess()).add(ms, "ms").format(format);
};
