export default {
  login: {
    SET_LOADING: "SET_LOADING",

    SET_EMAIL_VALUE: "SET_EMAIL_VALUE",
    SET_EMAIL_ERROR: "SET_EMAIL_ERROR",
    SET_EMAIL_SHOW_ERROR: "SET_EMAIL_SHOW_ERROR",

    SET_PASSWORD_VALUE: "SET_PASSWORD_VALUE",
    SET_PASSWORD_ERROR: "SET_PASSWORD_ERROR",
    SET_PASSWORD_SHOW_ERROR: "SET_PASSWORD_SHOW_ERROR",

    SET_API_ERROR: "SET_API_ERROR",
    SET_API_SHOW_ERROR: "SET_API_SHOW_ERROR",

    RESET_FIELDS: "RESET_FIELDS",
  },
  session: {
    SET_SESSION: "SET_SESSION",
    REMOVE_SESSION: "REMOVE_SESSION",
  },
  businessOwner: {
    globalSettings: {
      locations: {
        SET_LOCATION_LIST: "SET_LOCATION_LIST",
        SET_LOCATION_LIST_PROGRESS: "SET_LOCATION_LIST_PROGRESS",
        SET_LOCATION_ERROR: "SET_LOCATION_ERROR",
        SET_REFRESH_LOCATIONS: "SET_REFRESH_LOCATIONS",
        RESET_LOCATION_ERROR: "RESET_LOCATION_ERROR",
        SET_SELECTED_LOCATION: "SET_SELECTED_LOCATION",
        SHOW_CREATE_SCREEN: "SHOW_CREATE_SCREEN",
        SHOW_EDIT_SCREEN: "SHOW_EDIT_SCREEN",
        APPEND_TO_LOCATION_LIST: "APPEND_TO_LOCATION_LIST",
        UPDATE_LOCATION_LIST: "UPDATE_LOCATION_LIST",
        RESET_FULL_LOCATION: "RESET_FULL_LOCATION",
        SET_SHIFTS_VISIBILITY: "SET_SHIFTS_VISIBILITY",
        SET_ACTIVE_SHIFT_TAB: "SET_ACTIVE_SHIFT_TAB",
        TOGGLE_SHIFT_CHECKBOX: "TOGGLE_SHIFT_CHECKBOX",
        SET_SHIFTS: "SET_SHIFTS",
        SET_SHIFTS_ERROR: "SET_SHIFTS_ERROR",
        SET_SHIFTS_UPDATE_OR_CREATE_ERROR: "SET_SHIFTS_UPDATE_OR_CREATE_ERROR",
        SET_SHIFTS_CALL_IN_PROGRESS: "SET_SHIFTS_CALL_IN_PROGRESS",
        SET_SHIFT_TIME: "SET_SHIFT_TIME",
        APPLY_TIMING_TO_ALL: "APPLY_TIMING_TO_ALL",
        RESET_SHIFT: "RESET_SHIFT",
        RESET_ALL_SHIFTS_CHANGES: "RESET_ALL_SHIFTS_CHANGES",
        RESET_SHIFTS_TO_INIT: "RESET_SHIFTS_TO_INIT",
        SET_DISTRICTS: "SET_DISTRICTS",
        SET_DISTRICTS_CALL_IN_PROGRESS: "SET_DISTRICTS_CALL_IN_PROGRESS",
        SET_REGIONS: "SET_REGIONS",
        SET_REGIONS_CALL_IN_PROGRESS: "SET_REGIONS_CALL_IN_PROGRESS",
        SET_SETTINGS_UPDATE_IN_PROGRESS: "SET_SETTINGS_UPDATE_IN_PROGRESS",
        SET_LOCATIONS_AND_REGIONS_WITHOUT_HUB: "SET_LOCATIONS_AND_REGIONS_WITHOUT_HUB",
        SET_WITHOUT_HUB_CALL_IN_PROGRESS: "SET_WITHOUT_HUB_CALL_IN_PROGRESS",
        SHOW_SERVICE_PRICE_SCREEN: "SHOW_SERVICE_PRICE_SCREEN",
        SET_ACTIVE_LOCATION_SERVICES: "SET_ACTIVE_LOCATION_SERVICES",
        SET_SERVICES_CALL_LOADING: "SET_SERVICES_CALL_LOADING",
        SET_SERVICES_CALL_ERROR: "SET_SERVICES_CALL_ERROR",
        UPDATE_SERVICE_PRICE: "UPDATE_SERVICE_PRICE",
        CLOSE_SERVICE_PRICE: "CLOSE_SERVICE_PRICE",
        SET_LOCATION_DETAILS_LOADING: "SET_LOCATION_DETAILS_LOADING",
        SET_LOCATION_DETAILS_ERROR: "SET_LOCATION_DETAILS_ERROR",
        REFRESH_ACTIVE_LOCATION_DETAILS: "REFRESH_ACTIVE_LOCATION_DETAILS",
        SET_FULL_SERVICE_LIST_ERROR: "SET_FULL_SERVICE_LIST_ERROR",
        SHOW_DETAILS_SCREEN: "SHOW_DETAILS_SCREEN",
        SHOW_PRODUCT_PRICES_SCREEN: "SHOW_PRODUCT_PRICES_SCREEN",
        SET_SERVICES_AND_PRODUCTS_ACTIVE_TAB: "SET_SERVICES_AND_PRODUCTS_ACTIVE_TAB",
        SET_SERVICE_PRICES_UPDATE_ERROR: "SET_SERVICE_PRICES_UPDATE_ERROR",
        SET_DETAILS_UPDATE_IN_PROGRESS: "SET_DETAILS_UPDATE_IN_PROGRESS",

        // ADD LOCATION RELATED
        CLOSE_ADD_LOCATION_SCREEN: "CLOSE_ADD_LOCATION_SCREEN",
        CLOSE_EDIT_LOCATION_SCREEN: "CLOSE_EDIT_LOCATION_SCREEN",
        CHANGE_ADD_LOCATION_STEP: "CHANGE_ADD_LOCATION_STEP",
        SAVE_LOCATION_API_STARTED: "SAVE_LOCATION_API_STARTED",
        SAVE_LOCATION_API_SUCCESS: "SAVE_LOCATION_API_SUCCESS",
        SAVE_LOCATION_API_FAILURE: "SAVE_LOCATION_API_FAILURE",

        // TAX RATES RELATED
        // Fetch Tax Rates
        SHOW_HIDE_ADD_TAXRATE_SCREEN: "SHOW_HIDE_ADD_TAXRATE_SCREEN",
        SET_TAXES_LIST: "SET_TAXES_LIST",

        // Add Tax Rate
        APPEND_TO_TAX_LIST: "APPEND_TO_TAX_LIST",
        ADD_TAX_RATE_API_STARTED: "ADD_TAX_RATE_API_STARTED",
        ADD_TAX_RATE_API_SUCCESS: "ADD_TAX_RATE_API_SUCCESS",
        ADD_TAX_RATE_API_FAILURE: "ADD_TAX_RATE_API_FAILURE",
        EXIT_ADD_TAX_RATE_SCREEN: "EXIT_ADD_TAX_RATE_SCREEN",
        SET_NEW_TAX_RATE_WHILE_LOCATION_CREATION:
          "SET_NEW_TAX_RATE_WHILE_LOCATION_CREATION",

        // Add Tax Rate to a location
        TAX_SETTINGS_UPDATE_IN_PROGRESS: "TAX_SETTINGS_UPDATE_IN_PROGRESS",

        // Update Processing to a location
        PROCESSING_SETTINGS_UPDATE_IN_PROGRESS: "PROCESSING_SETTINGS_UPDATE_IN_PROGRESS",

        // Add DSA Licence to a location
        SELECTED_LOCATION_FIELD_CHANGE: "SELECTED_LOCATION_FIELD_CHANGE",

        // Three dot menu
        CLOSE_THREE_DOT_MENU_AND_SCREENS: "CLOSE_THREE_DOT_MENU_AND_SCREENS",
        SHOW_ESD_REGISTRATION_SCREEN: "SHOW_ESD_REGISTRATION_SCREEN",
        SET_HAS_ESD_ENABLED: "SET_HAS_ESD_ENABLED",
        SET_HAS_CCI_ENABLED: "SET_HAS_CCI_ENABLED",
        SET_ESD_VALUES: "SET_ESD_VALUES",
        SET_ESD_ERROR: "SET_ESD_ERROR",

        // RESET PASSWORD SCREEN
        SET_SHOW_RESET_PASSWORD_SCREEN: "SET_SHOW_RESET_PASSWORD_SCREEN",
        RESET_PASSWORD_API_STARTED: "RESET_PASSWORD_API_STARTED",
        RESET_PASSWORD_API_SUCCESS: "RESET_PASSWORD_API_SUCCESS",
        RESET_PASSWORD_API_FAILURE: "RESET_PASSWORD_API_FAILURE",

        //CHECKED IN EMPLOYEES SCREEN
        SET_SHOW_CHECKED_IN_EMPLOYEES_SCREEN: "SET_SHOW_CHECKED_IN_EMPLOYEES_SCREEN",
        FETCH_CHECKED_IN_EMPLOYEES_STARTED: "FETCH_CHECKED_IN_EMPLOYEES_STARTED",
        FETCH_CHECKED_IN_EMPLOYEES_SUCCESS: "FETCH_CHECKED_IN_EMPLOYEES_SUCCESS",
        FETCH_CHECKED_IN_EMPLOYEES_FAILURE: "FETCH_CHECKED_IN_EMPLOYEES_FAILURE",

        //STORE CLOSURES SCREEN
        SET_SHOW_STORE_CLOSURES_SCREEN: "SET_SHOW_STORE_CLOSURES_SCREEN",
        FETCH_STORE_CLOSURES_STARTED: "FETCH_STORE_CLOSURES_STARTED",
        FETCH_STORE_CLOSURES_SUCCESS: "FETCH_STORE_CLOSURES_SUCCESS",
        FETCH_STORE_CLOSURES_FAILURE: "FETCH_STORE_CLOSURES_FAILURE",
        CREATE_STORE_CLOSURES_STARTED: "CREATE_STORE_CLOSURES_STARTED",
        CREATE_STORE_CLOSURES_SUCCESS: "CREATE_STORE_CLOSURES_SUCCESS",
        CREATE_STORE_CLOSURES_FAILURE: "CREATE_STORE_CLOSURES_FAILURE",
        DELETE_STORE_CLOSURES_STARTED: "DELETE_STORE_CLOSURES_STARTED",
        DELETE_STORE_CLOSURES_SUCCESS: "DELETE_STORE_CLOSURES_SUCCESS",
        DELETE_STORE_CLOSURES_FAILURE: "DELETE_STORE_CLOSURES_FAILURE",
        PRE_CREATE_STORE_CLOSURES_STARTED: "PRE_CREATE_STORE_CLOSURES_STARTED",
        PRE_CREATE_STORE_CLOSURES_SUCCESS: "PRE_CREATE_STORE_CLOSURES_SUCCESS",
        PRE_CREATE_STORE_CLOSURES_FAILURE: "PRE_CREATE_STORE_CLOSURES_FAILURE",
        SET_IMPACTED_ORDERS: "SET_IMPACTED_ORDERS",

        // ZONES
        SET_SHOW_ZONES_EDITOR_SCREEN: "SET_SHOW_ZONES_EDITOR_SCREEN",
        SET_ZONE_STRATEGY: "SET_ZONE_STRATEGY",

        // DELIVERY SETTINGS
        SHOW_DELIVERY_SETTINGS_SCREEN: "SHOW_DELIVERY_SETTINGS_SCREEN",
        SET_DELIVERY_WIZARD_TYPE: "SET_DELIVERY_WIZARD_TYPE",
        SET_EDIT_DELIVERY_SETTINGS_SCREEN_TYPE: "SET_EDIT_DELIVERY_SETTINGS_SCREEN_TYPE",
        SET_ONDEMAND_DELIVERY_SETTINGS_ACTIVE_TOGGLE:
          "SET_ONDEMAND_DELIVERY_SETTINGS_ACTIVE_TOGGLE",
        SET_OWNDRIVER_DELIVERY_SETTINGS_ACTIVE_TOGGLE:
          "SET_OWNDRIVER_DELIVERY_SETTINGS_ACTIVE_TOGGLE",

        SET_DELIVERY_SETTINGS_CALL_PROGRESS: "SET_DELIVERY_SETTINGS_CALL_PROGRESS",
        SET_DELIVERY_SETTINGS: "SET_DELIVERY_SETTINGS",
        SET_DELIVERY_SETTINGS_ERROR: "SET_DELIVERY_SETTINGS_ERROR",
        TOGGLE_SERVICE_MULTISELECT_FOR_DELIVERY:
          "TOGGLE_SERVICE_MULTISELECT_FOR_DELIVERY",
      },
      accountSettings: {
        SET_ACCOUNT_DETAILS: "SET_ACCOUNT_DETAILS",
        UPDATE_ACCOUNT_DETAILS: "UPDATE_ACCOUNT_DETAILS",
        RESET_ACCOUNT_DETAILS: "RESET_ACCOUNT_DETAILS",
        SET_ACCOUNT_DETAILS_ERROR: "SET_ACCOUNT_DETAILS_ERROR",
        SET_ACCOUNT_DETAILS_FIELD_ERROR: "SET_ACCOUNT_DETAILS_FIELD_ERROR",
        RESET_ACCOUNT_DETAILS_ERROR: "RESET_ACCOUNT_DETAILS_ERROR",
        UPDATE_CALL_IN_PROGRESS: "UPDATE_CALL_IN_PROGRESS",
        SET_SHOW_REGION_MODAL: "SET_SHOW_REGION_MODAL",
        SET_MODAL_REGION: "SET_MODAL_REGION",
        ADD_NEW_DISTRICT_TO_MODAL_REGION: "ADD_NEW_DISTRICT_TO_MODAL_REGION",
        SET_REGION_MODAL_TEXT_FIELD_VALUE: "SET_REGION_MODAL_TEXT_FIELD_VALUE",
        SET_REGION_SAVE_IN_PROGRESS: "SET_REGION_SAVE_IN_PROGRESS",
        SET_REGION_SAVE_ERROR: "SET_REGION_SAVE_ERROR",
        SET_ACCOUNT_SETTINGS: "SET_ACCOUNT_SETTINGS",
        UPDATE_ACCOUNT_SETTINGS: "UPDATE_ACCOUNT_SETTINGS",
        SET_ACCOUNT_SETTINGS_ERROR: "SET_ACCOUNT_SETTINGS_ERROR",
        SET_SETTING_SAVE_IN_PROGRESS: "SET_SETTING_SAVE_IN_PROGRESS",
        SET_ACCOUNT_TAXES: "SET_ACCOUNT_TAXES",
        UPDATE_ACCOUNT_TAXES: "UPDATE_ACCOUNT_TAXES",
        SET_ACCOUNT_TAXES_ERROR: "SET_ACCOUNT_TAXES_ERROR",
        SET_TAX_SAVE_IN_PROGRESS: "SET_TAX_SAVE_IN_PROGRESS",
        SET_SHOW_TAX_MODAL: "SET_SHOW_TAX_MODAL",
        SET_TERMS_OF_SERVICE_URL: "SET_TERMS_OF_SERVICE_URL",
        SET_RECEIPT_CUSTOM_MESSAGE: "SET_RECEIPT_CUSTOM_MESSAGE",
      },
      devices: {
        SET_BATCH_LIST: "SET_BATCH_LIST",
        SET_BATCH_LIST_CALL_IN_PROGRESS: "SET_BATCH_LIST_CALL_IN_PROGRESS",
        RESET_BATCH_LIST: "RESET_BATCH_LIST",
        SET_BATCH_LIST_ERROR: "SET_BATCH_LIST_ERROR",
        RESET_BATCH_LIST_ERROR: "RESET_BATCH_LIST_ERROR",
        SET_LOCATIONS_LIST: "SET_LOCATIONS_LIST",
        SET_LOCATION_LIST_CALL_IN_PROGRESS: "SET_LOCATION_LIST_CALL_IN_PROGRESS",
        RESET_LOCATIONS_LIST: "RESET_LOCATIONS_LIST",
        SET_LOCATIONS_LIST_ERROR: "SET_LOCATIONS_LIST_ERROR",
        RESET_LOCATIONS_LIST_ERROR: "RESET_LOCATIONS_LIST_ERROR",
        SET_LOCATIONS_DROPDOWN_LIST: "SET_LOCATIONS_DROPDOWN_LIST",
        RESET_LOCATIONS_DROPDOWN_LIST: "RESET_LOCATIONS_DROPDOWN_LIST",
        SET_SELECTED_ITEM: "SET_SELECTED_ITEM",
        RESET_SELECTED_ITEM: "RESET_SELECTED_ITEM",
        SET_CHOSEN_LOCATION_ITEM: "SET_CHOSEN_LOCATION_ITEM",
        RESET_CHOSEN_LOCATION_ITEM: "RESET_CHOSEN_LOCATION_ITEM",
        REFRESH_ALL_LISTS: "REFRESH_ALL_LISTS",
        RESET_REFRESH_ALL_LISTS: "RESET_REFRESH_ALL_LISTS",
        SET_DEVICES_LIST: "SET_DEVICES_LIST",
        SET_DEVICES_LIST_CALL_IN_PROGRESS: "SET_DEVICES_LIST_CALL_IN_PROGRESS",
        RESET_DEVICES_LIST: "RESET_DEVICES_LIST",
        SET_ASSIGN_LOCATION_ERROR: "SET_ASSIGN_LOCATION_ERROR",
        RESET_ASSIGN_LOCATION_ERROR: "RESET_ASSIGN_LOCATION_ERROR",
        SET_ASSIGN_LOCATION_PROGRESS: "SET_ASSIGN_LOCATION_PROGRESS",
        RESET_FULL_STATE: "RESET_FULL_STATE",
      },
      taskManager: {
        SET_TASK_LIST: "SET_TASK_LIST",
        SET_REGIONS_LIST: "SET_REGIONS_LIST",
        SET_LOCATIONS_LIST: "SET_LOCATIONS_LIST",
        SET_SHIFTS_LIST: "SET_SHIFTS_LIST",
        SET_TASK_CALL_IN_PROGRESS: "SET_TASK_CALL_IN_PROGRESS",
        SET_TASK_LIST_ERROR: "SET_TASK_LIST_ERROR",
        SET_ACTIVE_TASK_ID: "SET_ACTIVE_TASK_ID",
        RESET_FULL_STATE: "RESET_FULL_STATE",
        UPDATE_ACTIVE_TASK: "UPDATE_ACTIVE_TASK",
        UPDATE_ACTIVE_TASK_FIELD: "UPDATE_ACTIVE_TASK_FIELD",
        SET_TASK_SAVE_IN_PROGRESS: "SET_TASK_SAVE_IN_PROGRESS",
        SET_TASK_SAVE_ERROR: "SET_TASK_SAVE_ERROR",
        SET_SHOULD_REFRESH_TASK_LIST: "SET_SHOULD_REFRESH_TASK_LIST",
        RESET_NEW_TASK: "RESET_NEW_TASK",
        SET_ACTIVE_ASSIGN_BOX: "SET_ACTIVE_ASSIGN_BOX",
        SET_LOCATION_ASSIGN_BOX_SELECTION: "SET_LOCATION_ASSIGN_BOX_SELECTION",
        SET_LOCATION_ONLY_ASSIGN_BOX_SELECTION: "SET_LOCATION_ONLY_ASSIGN_BOX_SELECTION",
        SET_LOCATION_ASSIGN_CHECKBOX_STATE: "SET_LOCATION_ASSIGN_CHECKBOX_STATE",
        SET_DAY_ASSIGN_CHECKBOX_STATE: "SET_DAY_ASSIGN_CHECKBOX_STATE",
        SET_SHIFT_ASSIGN_CHECKBOX_STATE: "SET_SHIFT_ASSIGN_CHECKBOX_STATE",
        SET_SELECTED_LOCATIONS: "SET_SELECTED_LOCATIONS",
      },
      teams: {
        SET_TEAM_MEMBER_LIST: "SET_TEAM_MEMBER_LIST",
        SET_TEAM_LIST_CALL_IN_PROGRESS: "SET_TEAM_LIST_CALL_IN_PROGRESS",
        SET_TEAM_MEMBER_LIST_ERROR: "SET_TEAM_MEMBER_LIST_ERROR",
        SET_ACTIVE_TEAM_MEMBER: "SET_ACTIVE_TEAM_MEMBER",
        REFRESH_TEAM_LIST: "REFRESH_TEAM_LIST",
        SET_TEAM_MEMBER_FIELD: "SET_TEAM_MEMBER_FIELD",
        SET_ACTIVE_TEAM_MEMBER_ERROR: "SET_ACTIVE_TEAM_MEMBER_ERROR",
        RESET_ACTIVE_TEAM_MEMBER_ERRORS: "RESET_ACTIVE_TEAM_MEMBER_ERRORS",
        SET_ALL_LOCATIONS: "SET_ALL_LOCATIONS",
        SET_SEARCH_IN_PROGRESS: "SET_SEARCH_IN_PROGRESS",
        SET_TEAM_MEMBER_SEARCH_TEXT: "SET_TEAM_MEMBER_SEARCH_TEXT",
        SET_TEAM_MEMBER_DETAILS_CALL_IN_PROGRESS:
          "SET_TEAM_MEMBER_DETAILS_CALL_IN_PROGRESS",
        SET_ACTIVE_TEAM_MEMBER_DETAILS_ERROR: "SET_ACTIVE_TEAM_MEMBER_DETAILS_ERROR",
        SET_ACTIVE_TEAM_MEMBER_DETAILS: "SET_ACTIVE_TEAM_MEMBER_DETAILS",
        SET_TEAM_MEMBER_NAME_IN_LIST: "SET_TEAM_MEMBER_NAME_IN_LIST",
        RESET_ACTIVE_TEAM_MEMBER_DETAILS: "RESET_ACTIVE_TEAM_MEMBER_DETAILS",
        SET_ARCHIVE_ERROR: "SET_ARCHIVE_ERROR",
      },
      services: {
        SET_LEGACY_ALL_SERVICES: "SET_LEGACY_ALL_SERVICES",
        SET_ALL_SERVICES: "SET_ALL_SERVICES",
        SET_ALL_SERVICES_COPY: "SET_ALL_SERVICES_COPY",
        SET_SERVICES_ERROR: "SET_SERVICES_ERROR",
        SET_SERVICES_CALL_PROGRESS: "SET_LOCATION_CALL_PROGRESS",
        SET_ACTIVE_SERVICE: "SET_ACTIVE_SERVICE",
        SET_SEARCH_RESULTS: "SET_SEARCH_RESULTS",
        SET_NEW_SERVICE_ACTIVE_ID: "SET_NEW_SERVICE_ACTIVE_ID", // Change the activeServiceId to the new service when created
        SET_NEW_SERVICE_ERROR: "SET_NEW_SERVICE_ERROR",
        SET_NEW_SERVICE_CALL_PROGRESS: "SET_NEW_SERVICE_CALL_PROGRESS",
        SET_SHOW_NEW_SERVICE_WIZARD: "SET_SHOW_NEW_SERVICE_WIZARD",
        SET_SERVICE_DETAILS_CALL_IN_PROGRESS: "SET_SERVICE_DETAILS_CALL_IN_PROGRESS",
        SET_ACTIVE_SERVICE_DETAILS: "SET_ACTIVE_SERVICE_DETAILS",
        SET_SERVICE_DETAILS_ERROR: "SET_SERVICE_DETAILS_ERROR",
        SET_SHOW_SERVICE_PRICES_SCREEN: "SET_SHOW_SERVICE_PRICES_SCREEN",
        SET_SHOW_UPDATE_SERVICE: "SET_SHOW_UPDATE_SERVICE",
        UPDATE_SERVICES_PRICE_PER_LOCATION: "UPDATE_SERVICES_PRICE_PER_LOCATION", // handleChange function to set redux state
        UPDATE_SERVICES_PRICE_PER_TIER: "UPDATE_SERVICES_PRICE_PER_TIER",
        SET_SERVICE_PRICE_PER_TIER: "SET_SERVICE_PRICE_PER_TIER",
        SET_PER_LOCATION_SERVICE_PRICES_CALL_PROGRESS:
          "SET_PER_LOCATION_SERVICE_PRICES_CALL_PROGRESS",
        SET_PER_LOCATION_SERVICE_PRICES_ERROR: "SET_PER_LOCATION_SERVICE_PRICES_ERROR",
        TOGGLE_MINIMUM_IN_PRICE_PER_LOCATION: "TOGGLE_MINIMUM_IN_PRICE_PER_LOCATION",
        APPLY_TO_ALL: "APPLY_TO_ALL",
        SET_SERVICES_CATEGORY_LIST: "SET_SERVICES_CATEGORY_LIST",
        SET_ACTIVE_ROUNDED_TAB: "SET_ACTIVE_ROUNDED_TAB",
        SET_SEARCH_IN_PROGRESS: "SET_SEARCH_IN_PROGRESS",
        SET_SERVICE_SEARCH_TEXT: "SET_SERVICE_SEARCH_TEXT",
        UPDATE_ACTIVE_SERVICE_DETAIL: "UPDATE_ACTIVE_SERVICE_DETAIL",
        UPDATE_SERVICE_NAME_IN_LIST: "UPDATE_SERVICE_NAME_IN_LIST",
        SET_SERVICE_DETAILS_UPDATE_IN_PROGRESS: "SET_SERVICE_DETAILS_UPDATE_IN_PROGRESS",
        SET_SERVICE_DETAILS_UPDATE_ERROR: "SET_SERVICE_DETAILS_UPDATE_ERROR",
        SET_SERVICE_DETAILS_UPDATED_ID: "SET_SERVICE_DETAILS_UPDATED_ID",
        SET_ACTIVE_TAB: "SET_ACTIVE_TAB",
        SET_HAS_MIN_PRICE_UPDATE_IN_PROGRESS: "SET_HAS_MIN_PRICE_UPDATE_IN_PROGRESS",
        // New Service Wizard price per location action types below :
        SET_SHOW_NEW_SERVICE_PRICING_SCREEN: "SET_SHOW_NEW_SERVICE_PRICING_SCREEN",
        SET_NEW_SERVICE_PRICING_CALL_PROGRESS: "SET_NEW_SERVICE_PRICING_CALL_PROGRESS",
        SET_NEW_SERVICE_PRICING_LOCATIONS_LIST: "SET_NEW_SERVICE_PRICING_LOCATIONS_LIST",
        SET_NEW_SERVICE_PRICING_LOCATIONS_ERROR:
          "SET_NEW_SERVICE_PRICING_LOCATIONS_ERROR",
        SET_NEW_SERVICE_PRICE_AT_LOCATION: "SET_NEW_SERVICE_PRICE_AT_LOCATION",
        SET_SELECT_ALL_SERVICE_LOCATIONS: "SET_SELECT_ALL_SERVICE_LOCATIONS",
        SET_APPLY_ALL_SERVICE_PRICES: "SET_APPLY_ALL_SERVICE_PRICES",
        SET_MINIMUM_TOGGLE_VALUE: "SET_MINIMUM_TOGGLE_VALUE",
        UPDATE_DEFAULT_PRICE_ARRAY_OF_SERVICE: "UPDATE_DEFAULT_PRICE_ARRAY_OF_SERVICE",
        INCREMENT_PRICE_UPDATE_COUNTER: "INCREMENT_PRICE_UPDATE_COUNTER",
        DECREMENT_PRICE_UPDATE_COUNTER: "DECREMENT_PRICE_UPDATE_COUNTER",
        SET_ARCHIVE_ERROR: "SET_ARCHIVE_ERROR",
        SET_SERVICES_TOP_LEVEL_STATE: "SET_SERVICES_TOP_LEVEL_STATE",
        SET_SERVICES_CATEGORY_LABELS: "SET_SERVICES_CATEGORY_LABELS",
        SET_SERVICES_CATEGORIES: "SET_SERVICES_CATEGORIES",
        SET_SERVICES_SUBSERVICES: "SET_SERVICES_SUBSERVICES",
        SET_SERVICES_PRICING_TYPES: "SET_SERVICES_PRICING_TYPES",
        SET_SHOW_NEW_CATEGORY_SCREEN: "SET_SHOW_NEW_CATEGORY_SCREEN",
        SET_SERVICE_CATEGORY: "SET_SERVICE_CATEGORY",
        UPDATE_SERVICE_CATEGORY: "UPDATE_SERVICE_CATEGORY",
        SET_NEW_CATEGORY_ID: "SET_NEW_CATEGORY_ID",
        UPDATE_SERVICE_LIST: "UPDATE_SERVICE_LIST",
        SET_SHOW_NEW_CATEGORY_SCREEN_IN_DETAILS:
          "SET_SHOW_NEW_CATEGORY_SCREEN_IN_DETAILS",
        SET_SERVICES_REFRESH: "SET_SERVICES_REFRESH",
        SET_CENTS20_SERVICE_SEARCH_TEXT: "SET_CENTS20_SERVICE_SEARCH_TEXT",
        CHANGE_FEATURED_PRICES: "CHANGE_FEATURED_PRICES",
        SET_IS_PRICING_LOADING: "SET_IS_PRICING_LOADING",
        SET_PRICING_ERROR: "SET_PRICING_ERROR",
      },
      drycleaning: {
        SET_ALL_DRYCLEANING_SERVICES: "SET_ALL_DRYCLEANING_SERVICES",
        SET_ALL_DRYCLEANING_SERVICES_COPY: "SET_ALL_DRYCLEANING_SERVICES_COPY",
        SET_DRYCLEANING_SERVICES_ERROR: "SET_DRYCLEANING_SERVICES_ERROR",
        SET_DRYCLEANING_SERVICES_CALL_PROGRESS: "SET_DRYCLEANING_SERVICES_CALL_PROGRESS",
        SET_ACTIVE_DRYCLEANING_SERVICE: "SET_ACTIVE_DRYCLEANING_SERVICE",
        SET_DRYCLEANING_SEARCH_RESULTS: "SET_DRYCLEANING_SEARCH_RESULTS",
        SET_NEW_DRYCLEANING_SERVICE_ACTIVE_ID: "SET_NEW_DRYCLEANING_SERVICE_ACTIVE_ID", // Change the activeServiceId to the new service when created
        SET_NEW_DRYCLEANING_SERVICE_ERROR: "SET_NEW_DRYCLEANING_SERVICE_ERROR",
        SET_NEW_DRYCLEANING_SERVICE_CALL_PROGRESS:
          "SET_NEW_DRYCLEANING_SERVICE_CALL_PROGRESS",
        SET_SHOW_DRYCLEANING_NEW_SERVICE_WIZARD:
          "SET_SHOW_DRYCLEANING_NEW_SERVICE_WIZARD",
        SET_DRYCLEANING_SERVICE_DETAILS_CALL_IN_PROGRESS:
          "SET_DRYCLEANING_SERVICE_DETAILS_CALL_IN_PROGRESS",
        SET_ACTIVE_DRYCLEANING_SERVICE_DETAILS: "SET_ACTIVE_DRYCLEANING_SERVICE_DETAILS",
        SET_DRYCLEANING_SERVICE_DETAILS_ERROR: "SET_DRYCLEANING_SERVICE_DETAILS_ERROR",
        SET_SHOW_DRYCLEANING_SERVICE_PRICES_SCREEN:
          "SET_SHOW_DRYCLEANING_SERVICE_PRICES_SCREEN",
        SET_SHOW_UPDATE_DRYCLEANING_SERVICE: "SET_SHOW_UPDATE_DRYCLEANING_SERVICE",
        UPDATE_DRYCLEANING_SERVICES_PRICE_PER_LOCATION:
          "UPDATE_DRYCLEANING_SERVICES_PRICE_PER_LOCATION", // handleChange function to set redux state
        SET_PER_LOCATION_DRYCLEANING_SERVICE_PRICES_CALL_PROGRESS:
          "SET_PER_LOCATION_DRYCLEANING_SERVICE_PRICES_CALL_PROGRESS",
        SET_PER_LOCATION_DRYCLEANING_SERVICE_PRICES_ERROR:
          "SET_PER_LOCATION_DRYCLEANING_SERVICE_PRICES_ERROR",
        TOGGLE_MINIMUM_IN_PRICE_PER_LOCATION: "TOGGLE_MINIMUM_IN_PRICE_PER_LOCATION",
        APPLY_TO_ALL: "APPLY_TO_ALL",
        SET_DRYCLEANING_SERVICES_CATEGORY_LIST: "SET_DRYCLEANING_SERVICES_CATEGORY_LIST",
        SET_ACTIVE_ROUNDED_TAB: "SET_ACTIVE_ROUNDED_TAB",
        SET_SEARCH_IN_PROGRESS: "SET_SEARCH_IN_PROGRESS",
        SET_DRYCLEANING_SERVICE_SEARCH_TEXT: "SET_DRYCLEANING_SERVICE_SEARCH_TEXT",
        UPDATE_ACTIVE_DRYCLEANING_SERVICE_DETAIL:
          "UPDATE_ACTIVE_DRYCLEANING_SERVICE_DETAIL",
        UPDATE_DRYCLEANING_SERVICE_NAME_IN_LIST:
          "UPDATE_DRYCLEANING_SERVICE_NAME_IN_LIST",
        SET_DRYCLEANING_SERVICE_DETAILS_UPDATE_IN_PROGRESS:
          "SET_DRYCLEANING_SERVICE_DETAILS_UPDATE_IN_PROGRESS",
        SET_DRYCLEANING_SERVICE_DETAILS_UPDATE_ERROR:
          "SET_DRYCLEANING_SERVICE_DETAILS_UPDATE_ERROR",
        SET_DRYCLEANING_SERVICE_DETAILS_UPDATED_ID:
          "SET_DRYCLEANING_SERVICE_DETAILS_UPDATED_ID",
        SET_ACTIVE_TAB: "SET_ACTIVE_TAB",
        SET_HAS_MIN_PRICE_UPDATE_IN_PROGRESS: "SET_HAS_MIN_PRICE_UPDATE_IN_PROGRESS",
        // New Service Wizard price per location action types below :
        SET_SHOW_NEW_DRYCLEANING_SERVICE_PRICING_SCREEN:
          "SET_SHOW_NEW_DRYCLEANING_SERVICE_PRICING_SCREEN",
        SET_NEW_DRYCLEANING_SERVICE_PRICING_CALL_PROGRESS:
          "SET_NEW_DRYCLEANING_SERVICE_PRICING_CALL_PROGRESS",
        SET_NEW_DRYCLEANING_SERVICE_PRICING_LOCATIONS_LIST:
          "SET_NEW_DRYCLEANING_SERVICE_PRICING_LOCATIONS_LIST",
        SET_NEW_DRYCLEANING_SERVICE_PRICING_LOCATIONS_ERROR:
          "SET_NEW_DRYCLEANING_SERVICE_PRICING_LOCATIONS_ERROR",
        SET_NEW_DRYCLEANING_SERVICE_PRICE_AT_LOCATION:
          "SET_NEW_DRYCLEANING_SERVICE_PRICE_AT_LOCATION",
        SET_SELECT_ALL_DRYCLEANING_SERVICE_LOCATIONS:
          "SET_SELECT_ALL_DRYCLEANING_SERVICE_LOCATIONS",
        SET_APPLY_ALL_DRYCLEANING_SERVICE_PRICES:
          "SET_APPLY_ALL_DRYCLEANING_SERVICE_PRICES",
        SET_MINIMUM_TOGGLE_VALUE: "SET_MINIMUM_TOGGLE_VALUE",
        UPDATE_DEFAULT_PRICE_ARRAY_OF_DRYCLEANING_SERVICE:
          "UPDATE_DEFAULT_PRICE_ARRAY_OF_DRYCLEANING_SERVICE",
        INCREMENT_PRICE_UPDATE_COUNTER: "INCREMENT_PRICE_UPDATE_COUNTER",
        DECREMENT_PRICE_UPDATE_COUNTER: "DECREMENT_PRICE_UPDATE_COUNTER",
        SET_UPDATE_VALUES: "SET_UPDATE_VALUES",
        SET_ARCHIVE_ERROR: "SET_ARCHIVE_ERROR",
        SET_DRYCLEANING_SERVICES_TOP_LEVEL_STATE:
          "SET_DRYCLEANING_SERVICES_TOP_LEVEL_STATE",
        SET_DRYCLEANING_SERVICES_CATEGORY_LABELS:
          "SET_DRYCLEANING_SERVICES_CATEGORY_LABELS",
        SET_DRYCLEANING_SERVICES_CATEGORIES: "SET_DRYCLEANING_SERVICES_CATEGORIES",
        SET_DRYCLEANING_SERVICES_SUBSERVICES: "SET_DRYCLEANING_SERVICES_SUBSERVICES",
        SET_DRYCLEANING_SERVICES_PRICING_TYPES: "SET_DRYCLEANING_SERVICES_PRICING_TYPES",
        SET_SHOW_NEW_CATEGORY_SCREEN: "SET_SHOW_NEW_CATEGORY_SCREEN",
        SET_DRYCLEANING_SERVICE_CATEGORY: "SET_DRYCLEANING_SERVICE_CATEGORY",
        UPDATE_DRYCLEANING_SERVICE_CATEGORY: "UPDATE_DRYCLEANING_SERVICE_CATEGORY",
        SET_NEW_DRYCLEANING_CATEGORY_ID: "SET_NEW_DRYCLEANING_CATEGORY_ID",
        UPDATE_DRYCLEANING_SERVICE_LIST: "UPDATE_DRYCLEANING_SERVICE_LIST",
        SET_SHOW_NEW_CATEGORY_SCREEN_IN_DETAILS:
          "SET_SHOW_NEW_CATEGORY_SCREEN_IN_DETAILS",
        SET_SERVICES_REFRESH: "SET_SERVICES_REFRESH",
        SET_IS_PRICING_LOADING: "SET_IS_PRICING_LOADING",
        SET_PRICING_ERROR: "SET_PRICING_ERROR",
        UPDATE_SERVICES_PRICE_PER_TIER: "UPDATE_SERVICES_PRICE_PER_TIER",
        SET_SERVICE_PRICE_PER_TIER: "SET_SERVICE_PRICE_PER_TIER",
        CHANGE_FEATURED_PRICES: "CHANGE_FEATURED_PRICES",
      },
      modifiers: {
        SET_MODIFIERS_CALL_PROGRESS: "SET_MODIFIERS_CALL_PROGRESS",
        SET_MODIFIERS_LIST: "SET_MODIFIERS_LIST",
        SET_MODIFIERS_CALL_ERROR: "SET_MODIFIERS_CALL_ERROR",
        SET_CREATE_MODIFIER_CALL_PROGRESS: "SET_CREATE_MODIFIER_CALL_PROGRESS",
        SET_CREATE_MODIFIER_SUCCESS: "SET_CREATE_MODIFIER_SUCCESS",
        SET_CREATE_MODIFIER_CALL_ERROR: "SET_CREATE_MODIFIER_CALL_ERROR",
        TOGGLE_MODIFIER_IS_ENABLED: "TOGGLE_MODIFIER_IS_ENABLED",
        TOGGLE_MODIFIER_ERROR: "TOGGLE_MODIFIER_ERROR",
        CLEAR_MODIFIERS: "CLEAR_MODIFIERS",
        SET_UPDATE_VALUES: "SET_UPDATE_VALUES",
        SET_MODIFIER_REFRESH: "SET_MODIFIER_REFRESH",
      },
      products: {
        SET_ACTIVE_TAB: "SET_ACTIVE_TAB",
        UPDATE_ACTIVE_PRODUCT_DETAIL: "UPDATE_ACTIVE_PRODUCT_DETAIL",
        SET_PRODUCT_DETAILS_UPDATE_ERROR: "SET_PRODUCT_DETAILS_UPDATE_ERROR",
        SET_NEW_PRODUCT_CALL_PROGRESS: "SET_NEW_PRODUCT_CALL_PROGRESS",
        SET_NEW_PRODUCT: "SET_NEW_PRODUCT",
        SET_NEW_PRODUCT_ERROR: "SET_NEW_PRODUCT_ERROR",
        SET_NEW_CATEGORY_CALL_PROGRESS: "SET_NEW_CATEGORY_CALL_PROGRESS",
        SET_NEW_CATEGORY_ERROR: "SET_NEW_CATEGORY_ERROR",
        SET_PRODUCTS_LIST_CALL_PROGRESS: "SET_PRODUCTS_LIST_CALL_PROGRESS",
        SET_PRODUCTS_LIST: "SET_PRODUCTS_LIST",
        SET_PRODUCTS_LIST_COPY: "SET_PRODUCTS_LIST_COPY",
        SET_PRODUCT_CATEGORIES_LIST: "SET_PRODUCT_CATEGORIES_LIST",
        SET_PRODUCTS_LIST_ERROR: "SET_PRODUCTS_LIST_ERROR",
        SET_ACTIVE_PRODUCT: "SET_ACTIVE_PRODUCT",
        SET_PRODUCT_SEARCH_TEXT: "SET_PRODUCT_SEARCH_TEXT",
        SET_SHOW_NEW_PRODUCT_WIZARD: "SET_SHOW_NEW_PRODUCT_WIZARD",
        SET_SHOW_NEW_CATEGORY_SCREEN: "SET_SHOW_NEW_CATEGORY_SCREEN",
        SET_PRODUCT_DETAILS_LOADING: "SET_PRODUCT_DETAILS_LOADING",
        SET_PRODUCT_DETAILS_ERROR: "SET_PRODUCT_DETAILS_ERROR",
        SET_ACTIVE_PRODUCT_DETAILS: "SET_ACTIVE_PRODUCT_DETAILS",
        SET_FILESTACK_KEY: "SET_FILESTACK_KEY",
        SET_FILESTACK_KEY_ERROR: "SET_FILESTACK_KEY_ERROR",
        SET_SEARCH_IN_PROGRESS: "SET_SEARCH_IN_PROGRESS",
        SET_SERVICES_CATEGORY_LIST: "SET_SERVICES_CATEGORY_LIST",
        SET_SERVICE_SEARCH_TEXT: "SET_SERVICE_SEARCH_TEXT",
        SET_PRODUCT_DETAILS_UPDATE_LOADING: "SET_PRODUCT_DETAILS_UPDATE_LOADING",
        UPDATE_PRODUCT_ID: "UPDATE_PRODUCT_ID",
        SET_SHOW_NEW_PRODUCT_PRICING_SCREEN: "SET_SHOW_NEW_PRODUCT_PRICING_SCREEN",
        SET_NEW_PRODUCT_PRICING_CALL_PROGRESS: "SET_NEW_PRODUCT_PRICING_CALL_PROGRESS",
        SET_NEW_PRODUCT_PRICING_LOCATIONS_LIST: "SET_NEW_PRODUCT_PRICING_LOCATIONS_LIST",
        SET_NEW_PRODUCT_PRICING_LOCATIONS_ERROR:
          "SET_NEW_PRODUCT_PRICING_LOCATIONS_ERROR",
        SET_NEW_PRODUCT_PRICE_AT_LOCATION: "SET_NEW_PRODUCT_PRICE_AT_LOCATION",
        SET_SELECT_ALL_PRODUCT_LOCATIONS: "SET_SELECT_ALL_PRODUCT_LOCATIONS",
        SET_APPLY_ALL_PRODUCT_PRICES: "SET_APPLY_ALL_PRODUCT_PRICES",
        SET_SHOW_NEW_CATEGORY_SCREEN_IN_DETAILS:
          "SET_SHOW_NEW_CATEGORY_SCREEN_IN_DETAILS",
        UPDATE_INVENTORY_ITEM_AND_STATUS: "UPDATE_INVENTORY_ITEM_AND_STATUS",
        UPDATE_PRODUCT_PRICE_PER_LOCATION: "UPDATE_PRODUCT_PRICE_PER_LOCATION",
        SET_IS_PRICING_LOADING: "SET_IS_PRICING_LOADING",
        SET_PRICING_ERROR: "SET_PRICING_ERROR",
        UPDATE_PRODUCT_PRICE_PER_TIER: "UPDATE_PRODUCT_PRICE_PER_TIER",
        SET_PRODUCT_PRICE_PER_TIER: "SET_PRODUCT_PRICE_PER_TIER",
        APPLY_TO_ALL: "APPLY_TO_ALL",
        CHANGE_FEATURED_PRICES: "CHANGE_FEATURED_PRICES",
      },
      categories: {
        SET_ACTIVE_TAB: "SET_ACTIVE_TAB",
        SET_SUBCATEGORIES: "SET_SUBCATEGORIES",
        SET_SUBCATEGORIES_ERROR: "SET_SUBCATEGORIES_ERROR",
        SET_SUBCATEGORIES_LOADING: "SET_SUBCATEGORIES_LOADING",
        SET_SUBCATEGORY_UPDATING: "SET_SUBCATEGORY_UPDATING",
        SET_TURNAROUND_TIME: "SET_TURNAROUND_TIME",
        UPDATE_SUBCATEGORY: "UPDATE_SUBCATEGORY",
        SET_ACTIVE_SUBCATEGORY: "SET_ACTIVE_SUBCATEGORY",
        SET_NEW_CATEGORY_CALL_PROGRESS: "SET_NEW_CATEGORY_CALL_PROGRESS",
        SET_CATEGORY_CALL_PROGRESS: "SET_CATEGORY_CALL_PROGRESS",
        SET_NEW_CATEGORY_ERROR: "SET_NEW_CATEGORY_ERROR",
        SET_TURNAROUND_TIME_ERROR: "SET_TURNAROUND_TIME_ERROR",
        SET_TURNAROUND_TIME_UPDATE_IN_PROGRESS: "SET_TURNAROUND_TIME_UPDATE_IN_PROGRESS",
      },
      promotions: {
        SET_ALL_PROMOTIONS: "SET_ALL_PROMOTIONS",
        SET_ALL_PROMOTIONS_COPY: "SET_ALL_PROMOTIONS_COPY",
        SET_PROMOTIONS_ERROR: "SET_PROMOTIONS_ERROR",
        SET_PROMOTIONS_CALL_PROGRESS: "SET_PROMOTIONS_CALL_PROGRESS",
        SET_ACTIVE_PROMOTION: "SET_ACTIVE_PROMOTION",
        SET_SEARCH_RESULTS: "SET_SEARCH_RESULTS",
        SET_NEW_PROMOTION_ACTIVE_ID: "SET_NEW_PROMOTION_ACTIVE_ID",
        SET_NEW_PROMOTION_ERROR: "SET_NEW_PROMOTION_ERROR",
        SET_NEW_PROMOTION_CALL_PROGRESS: "SET_NEW_PROMOTION_CALL_PROGRESS",
        SET_SHOW_NEW_PROMOTION_WIZARD: "SET_SHOW_NEW_PROMOTION_WIZARD",
        SET_PROMOTION_DETAILS_CALL_IN_PROGRESS: "SET_PROMOTION_DETAILS_CALL_IN_PROGRESS",
        SET_ACTIVE_PROMOTION_DETAILS: "SET_ACTIVE_PROMOTION_DETAILS",
        SET_PROMOTION_DETAILS_ERROR: "SET_PROMOTION_DETAILS_ERROR",
        SET_SHOW_UPDATE_PROMOTION: "SET_SHOW_UPDATE_PROMOTION",
        TOGGLE_ACTIVE_PROMOTION: "TOGGLE_ACTIVE_PROMOTION",
        APPLY_TO_ALL: "APPLY_TO_ALL",
        SET_ACTIVE_ROUNDED_TAB: "SET_ACTIVE_ROUNDED_TAB",
        SET_SEARCH_IN_PROGRESS: "SET_SEARCH_IN_PROGRESS",
        SET_PROMOTION_SEARCH_TEXT: "SET_PROMOTION_SEARCH_TEXT",
        UPDATE_ACTIVE_PROMOTION_DETAIL: "UPDATE_ACTIVE_PROMOTION_DETAIL",
        UPDATE_PROMOTION_NAME_IN_LIST: "UPDATE_PROMOTION_NAME_IN_LIST",
        SET_PROMOTION_DETAILS_UPDATE_IN_PROGRESS:
          "SET_PROMOTION_DETAILS_UPDATE_IN_PROGRESS",
        SET_PROMOTION_DETAILS_UPDATE_ERROR: "SET_PROMOTION_DETAILS_UPDATE_ERROR",
        SET_PROMOTION_DETAILS_UPDATED_ID: "SET_PROMOTION_DETAILS_UPDATED_ID",
        UPDATE_STEP_COUNT_IN_WIZARD: "UPDATE_STEP_COUNT_IN_WIZARD",
        SET_LOCATIONS_CALL_PROGRESS: "SET_LOCATIONS_CALL_PROGRESS",
        SET_ALL_LOCATIONS: "SET_ALL_LOCATIONS",
        SET_LOCATIONS_LIST_ERROR: "SET_LOCATIONS_LIST_ERROR",
        SET_SELECTED_LOCATION: "SET_SELECTED_LOCATION",
        SHOW_PRODUCTS_AND_SERVICES_SCREEN: "SHOW_PRODUCTS_AND_SERVICES_SCREEN",
        SET_NEW_PROMO_NAME_AND_DISCOUNT_VALUE: "SET_NEW_PROMO_NAME_AND_DISCOUNT_VALUE",

        // Loyalty Program
        UPDATE_LOYALTY_PROGRAM: "UPDATE_LOYALTY_PROGRAM",
        UPDATE_LOYALTY_PROGRAM_ERROR: "UPDATE_LOYALTY_PROGRAM_ERROR",

        // Action types related to Services and Products Tab
        SET_SERVICES_CALL_PROGRESS: "SET_SERVICES_CALL_PROGRESS",
        SET_SERVICES_LIST_CALL_ERROR: "SET_SERVICES_LIST_CALL_ERROR",
        SET_SERVICES_LIST: "SET_SERVICES_LIST",
        SET_PRODUCTS_CALL_PROGRESS: "SET_PRODUCTS_CALL_PROGRESS",
        SET_PRODUCTS_LIST_CALL_ERROR: "SET_PRODUCTS_LIST_CALL_ERROR",
        SET_PRODUCTS_LIST: "SET_PRODUCTS_LIST",
        UPDATE_SERVICES_LIST: "UPDATE_SERVICES_LIST",
        SET_SELECT_ALL_SERVICES: "SET_SELECT_ALL_SERVICES",
        HANDLE_SERVICES_TAB_SWITCH: "HANDLE_SERVICES_TAB_SWITCH",
        UPDATE_PRODUCTS_LIST: "UPDATE_PRODUCTS_LIST",
        SET_SELECT_ALL_PRODUCTS: "SET_SELECT_ALL_PRODUCTS",
        SET_ITEMS_COUNT: "SET_ITEMS_COUNT",
        RESET_SERVICES_AND_PRODUCTS: "RESET_SERVICES_AND_PRODUCTS",
        HANDLE_PRODUCTS_TAB_SWITCH: "HANDLE_PRODUCTS_TAB_SWITCH",
        UPDATE_SERVICES_AND_PRODUCTS_COPY: "UPDATE_SERVICES_AND_PRODUCTS_COPY",
        RESET_WIZARD_REDUX_STATE: "RESET_WIZARD_REDUX_STATE",
        GET_ALL_LOCATIONS_STARTED: "GET_ALL_LOCATIONS_STARTED",
        GET_ALL_LOCATIONS_SUCCEEDED: "GET_ALL_LOCATIONS_SUCCEEDED",
        GET_ALL_LOCATIONS_FAILED: "GET_ALL_LOCATIONS_FAILED",
        CLEAR_SERVICES_AND_PRODUCTS_DATA: "CLEAR_SERVICES_AND_PRODUCTS_DATA",
        SET_NEW_SERVICES_LIST: "SET_NEW_SERVICES_LIST",

        // PROMOTION DETAILS
        GET_PROMOTION_DETAILS_STARTED: "GET_PROMOTION_DETAILS_STARTED",
        GET_PROMOTION_DETAILS_SUCCEEDED: "GET_PROMOTION_DETAILS_SUCCEEDED",
        GET_PROMOTION_DETAILS_FAILED: "GET_PROMOTION_DETAILS_FAILED",
        ACTIVE_PROMO_DETAILS_CHANGED: "ACTIVE_PROMO_DETAILS_CHANGED",
        SET_DETAILS_HAS_END_DATE: "SET_DETAILS_HAS_END_DATE",
        UPDATE_PROMO_DETAIL_IN_LIST: "UPDATE_PROMO_DETAIL_IN_LIST",
        SET_UPDATE_ERRORS: "SET_UPDATE_ERRORS",

        // Action types related to creating a new promotion
        SET_CREATE_PROMOTION_CALL_PROGRESS: "SET_CREATE_PROMOTION_CALL_PROGRESS",
        SET_CREATE_PROMOTION_CALL_ERROR: "SET_CREATE_PROMOTION_CALL_ERROR",
        SET_DETAILS_PRODUCT_PICKER_VISIBILITY: "SET_DETAILS_PRODUCT_PICKER_VISIBILITY",

        // Action types related to Referral Program
        GET_REFERRAL_PROGRAM_STARTED: "GET_REFERRAL_PROGRAM_STARTED",
        GET_REFERRAL_PROGRAM_SUCCEEDED: "GET_REFERRAL_PROGRAM_SUCCEEDED",
        GET_REFERRAL_PROGRAM_FAILED: "GET_REFERRAL_PROGRAM_FAILED",
        CREATE_REFERRAL_PROGRAM_STARTED: "CREATE_REFERRAL_PROGRAM_STARTED",
        CREATE_REFERRAL_PROGRAM_SUCCEEDED: "CREATE_REFERRAL_PROGRAM_SUCCEEDED",
        CREATE_REFERRAL_PROGRAM_FAILED: "CREATE_REFERRAL_PROGRAM_FAILED",
        UPDATE_REFERRAL_PROGRAM_STARTED: "UPDATE_REFERRAL_PROGRAM_STARTED",
        UPDATE_REFERRAL_PROGRAM_SUCCEEDED: "UPDATE_REFERRAL_PROGRAM_SUCCEEDED",
        UPDATE_REFERRAL_PROGRAM_FAILED: "UPDATE_REFERRAL_PROGRAM_FAILED",
        GET_REFERRAL_ACTIVITY_STARTED: "GET_REFERRAL_ACTIVITY_STARTED",
        GET_REFERRAL_ACTIVITY_SUCCEEDED: "GET_REFERRAL_ACTIVITY_SUCCEEDED",
        GET_REFERRAL_ACTIVITY_FAILED: "GET_REFERRAL_ACTIVITY_FAILED",
      },
      preferences: {
        ADD_PREFERENCES: "ADD_PREFERENCES",
        ADD_PREFERENCES_CALL_PROGRESS: "ADD_PREFERENCES_CALL_PROGRESS",
        ADD_PREFERENCES_CALL_ERROR: "ADD_PREFERENCES_CALL_ERROR",
        FETCH_PREFERENCES: "FETCH_PREFERENCES",
        FETCH_PREFERENCES_CALL_PROGRESS: "FETCH_PREFERENCES_CALL_PROGRESS",
        FETCH_PREFERENCES_CALL_ERROR: "FETCH_PREFERENCES_CALL_ERROR",
        REMOVE_PREFERENCE_CALL_PROGRESS: "REMOVE_PREFERENCE_CALL_PROGRESS",
        REMOVE_PREFERENCE: "REMOVE_PREFERENCE",
        REMOVE_PREFERENCE_CALL_ERROR: "REMOVE_PREFERENCE_CALL_ERROR",
        UPDATE_PREFERENCE_CALL_PROGRESS: "UPDATE_PREFERENCE_CALL_PROGRESS",
        UPDATE_PREFERENCE: "UPDATE_PREFERENCE",
        UPDATE_PREFERENCE_CALL_ERROR: "UPDATE_PREFERENCE_CALL_ERROR",
        UPDATE_PREFERENCE_OPTION_CALL_PROGRESS: "UPDATE_PREFERENCE_OPTION_CALL_PROGRESS",
        UPDATE_PREFERENCE_OPTION: "UPDATE_PREFERENCE_OPTION",
        UPDATE_PREFERENCE_OPTION_CALL_ERROR: "UPDATE_PREFERENCE_OPTION_CALL_ERROR",
        REMOVE_PREFERENCE_OPTION: "REMOVE_PREFERENCE_OPTION",
        REMOVE_PREFERENCE_OPTION_CALL_PROGRESS: "REMOVE_PREFERENCE_OPTION_CALL_PROGRESS",
        REMOVE_PREFERENCE_OPTION_CALL_ERROR: "REMOVE_PREFERENCE_OPTION_CALL_ERROR",
        ADD_PREFERENCE_OPTION: "ADD_PREFERENCE_OPTION",
        ADD_PREFERENCE_OPTION_CALL_PROGRESS: "ADD_PREFERENCE_OPTION_CALL_PROGRESS",
        ADD_PREFERENCE_OPTION_CALL_ERROR: "ADD_PREFERENCE_OPTION_CALL_ERROR",
        CHANGE_DEFAULT_OPTION: "CHANGE_DEFAULT_OPTION",
        CHANGE_DEFAULT_OPTION_CALL_PROGRESS: "CHANGE_DEFAULT_OPTION_CALL_PROGRESS",
        CHANGE_DEFAULT_OPTION_CALL_ERROR: "CHANGE_DEFAULT_OPTION_CALL_ERROR",
      },
    },
    analyticsDashboard: {
      // Section Visibility Preferences.
      CUSTOMERS_TOGGLE_DATA_CALL_SUCCEEDED: "CUSTOMERS_TOGGLE_DATA_CALL_SUCCEEDED",
      MACHINES_TOGGLE_DATA_CALL_SUCCEEDED: "MACHINES_TOGGLE_DATA_CALL_SUCCEEDED",
      ORDERS_TOGGLE_DATA_CALL_SUCCEEDED: "ORDERS_TOGGLE_DATA_CALL_SUCCEEDED",
      VOLUME_TOGGLE_DATA_CALL_SUCCEEDED: "VOLUME_TOGGLE_DATA_CALL_SUCCEEDED",
      PRODUCTS_TOGGLE_DATA_CALL_SUCCEEDED: "PRODUCTS_TOGGLE_DATA_CALL_SUCCEEDED",
      PROMOTIONS_TOGGLE_DATA_CALL_SUCCEEDED: "PROMOTIONS_TOGGLE_DATA_CALL_SUCCEEDED",
      REVENUE_TOGGLE_DATA_CALL_SUCCEEDED: "REVENUE_TOGGLE_DATA_CALL_SUCCEEDED",
      CONNECT_TOGGLE_DATA_CALL_SUCCEEDED: "CONNECT_TOGGLE_DATA_CALL_SUCCEEDED",

      // Dashboard data
      FILTER_DATA_PERIOD: "FILTER_DATA_PERIOD",
    },
    dashboard: {
      SET_LOCATIONS_LIST: "SET_LOCATIONS_LIST",
      SET_IS_LOCATIONS_EMPTY: "SET_IS_LOCATIONS_EMPTY",
      SET_LOCATIONS_ERROR: "SET_LOCATIONS_ERROR",
      SET_SELECTED_LOCATION: "SET_SELECTED_LOCATION",
      SET_LOCATION_CALL_PROGRESS: "SET_LOCATION_CALL_PROGRESS",
      RESET_DASHBOARD_DATA: "RESET_DASHBOARD_DATA",
      SET_SELECTED_LOCATIONS: "SET_SELECTED_LOCATIONS",
      SET_ALL_LOCATIONS: "SET_ALL_LOCATIONS",
    },
    machines: {
      SET_DEVICE_LIST: "SET_DEVICE_LIST",
      SET_DEVICES_ERROR: "SET_DEVICES:ERROR",
      SET_MACHINE_LIST: "SET_MACHINE_LIST",
      SET_MACHINE_LIST_ERROR: "SET_MACHINE_LIST_ERROR",
      SET_SELECTED_ITEM: "SET_SELECTED_ITEM",
      SET_SELECTED_ITEM_TYPE: "SET_SELECTED_ITEM_TYPE",
      UPDATE_WIZARD: "UPDATE_WIZARD",
      SET_MACHINE_MODEL_LIST: "SET_MACHINE_MODEL_LIST",
      SET_MACHINE_MODEL_LIST_ERROR: "SET_MACHINE_MODEL_LIST_ERROR",
      SET_MODEL_LOAD_TYPES: "SET_MODEL_LOAD_TYPES",
      SET_MODEL_LOAD_TYPES_ERROR: "SET_MODEL_LOAD_TYPES_ERROR",
      SET_MACHINE_CALL_PROGRESS: "SET_MACHINE_CALL_PROGRESS",
      SET_DEVICE_CALL_PROGRESS: "SET_DEVICE_CALL_PROGRESS",
      RESET_WIZARD_DATA: "RESET_WIZARD_DATA",
      RESET_MACHINES_STATE: "RESET_MACHINES_STATE",
      SET_PAIRING_ERROR: "SET_PAIRING_ERROR",
      REFRESH_DATA: "REFRESH_DATA",
      AUTO_SELECT_ITEM: "AUTO_SELECT_ITEM",
      TOGGLE_MACHINE_MENU: "TOGGLE_MACHINE_MENU",
      UPDATE_MACHINE_STATUS: "UPDATE_MACHINE_STATUS",
      UPDATE_DEVICE_STATUS: "UPDATE_DEVICE_STATUS",
      SET_RUN_MACHINE_STATUS: "SET_RUN_MACHINE_STATUS",
      SET_SHOW_RUN_WIZARD: "SET_SHOW_RUN_WIZARD",
      SET_RUN_WIZARD_STEP: "SET_RUN_WIZARD_STEP",
      SET_RUN_WIZARD_DATA_FIELD: "SET_RUN_WIZARD_DATA_FIELD",
      RESET_RUN_WIZARD: "RESET_RUN_WIZARD",
      SET_WIZ_CUSTOMER_SEARCH_LIST: "SET_WIZ_CUSTOMER_SEARCH_LIST",
      SET_NEW_CUSTOMER_ERROR: "SET_NEW_CUSTOMER_ERROR",
      CREATE_CUSTOMER_INITIATED: "CREATE_CUSTOMER_INITIATED",
      CREATE_CUSTOMER_FAILED: "CREATE_CUSTOMER_FAILED",
      CREATE_CUSTOMER_SUCCESS: "CREATE_CUSTOMER_SUCCESS",
      MACHINE_RUN_REQUEST_INITIATED: "MACHINE_RUN_REQUEST_INITIATED",
      MACHINE_RUN_REQUEST_SUCCESS: "MACHINE_RUN_REQUEST_SUCCESS",
      MACHINE_RUN_REQUEST_FAILED: "MACHINE_RUN_REQUEST_FAILED",
      MACHINE_STATUS_UPDATED: "MACHINE_STATUS_UPDATED",
      MACHINE_RUNNING_STATUS_UPDATED: "MACHINE_RUNNING_STATUS_UPDATED",
    },
    customers: {
      SET_FILTERS_POPOUT_VISIBILITY: "SET_FILTERS_POPOUT_VISIBILITY",
    },
    invoices: {
      TOGGLE_CUSTOMER_LIST_CHANGED: "TOGGLE_CUSTOMER_LIST_CHANGED",
      SET_SHOW_INVOICES: "SET_SHOW_INVOICES",
    },
    messenger: {
      SET_CONVERSATIONS_LIST_SEARCH_PARAMS: "SET_CONVERSATIONS_LIST_SEARCH_PARAMS",
    },
  },
  resetPassword: {
    SET_NEW_PASSWORD: "SET_NEW_PASSWORD",
    SET_RE_ENTER_PASSWORD: "SET_RE_ENTER_PASSWORD",
    SET_ERROR_MESSAGE: "SET_ERROR_PASSWORD",
    SET_RESET_TOKEN_STATUS: "SET_RESET_TOKEN_STATUS",
    SET_RESET_TOKEN: "SET_RESET_TOKEN",
    SET_RESET_SUCCESS: "SET_RESET_SUCCESS",
  },
  forgotPassword: {
    SET_ERROR: "SET_ERROR",
    SET_EMAIL: "SET_EMAIL",
    SET_SUBMISSION_SUCCESS: "SET_SUBMISSION_SUCCESS",
  },
  listFiltersBar: {
    CHANGE_FILTERS: "CHANGE_FILTERS",
    SET_ACTIVE_TAB: "SET_ACTIVE_TAB",
    SET_SEARCH_STRING: "SET_SEARCH_STRING",
    SET_UPDATE_WARNING: "SET_UPDATE_WARNING",
  },
} as const;
